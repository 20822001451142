export const ROUTES = [{
  title: 'Home',
  url: '/'
}, {
  title: 'What to Bring to Your Tax Appointment',
  url: '/what-to-bring'
}, {
  title: 'Filing an Extension',
  url: '/filing-an-extension'
}]

export const HOME_CONTENT = `
<div class="post-content box mark-links">
	<p>Leslie Fields has been a Certified Public Accountant in the state of New York for over 25 years and is skilled in a variety of areas.</p>
  <ul>
    <li>Personal and corporate taxes</li>
    <li>Accounting for businesses</li>
    <li>Financial statement preparation</li>
    <li>Budgets</li>
  </ul>
  <h2>Contact Information</h2>
  <p>Cell Phone: (908) 553-7700<br>
  Office Phone: (212) 867-4273<br>
  Fax: (212) 867-4274<br>
  E-mail address: Lfcpa1@msn.com or Lesliefields123@gmail.com<br>
  Contact by phone is the preferred method of communication.</p>
  <h2>Credentials</h2>
  <p>B.S. in Accounting from the University of Maryland<br>
  Member of the New York State Society of CPAs</p>
</div>
`

export const WHAT_TO_BRING_CONTENT = `
<div>
  <ul>
  <li>W-2 forms</li>
  <li>1099-MISC forms for independent contractors</li>
  <li>1099 interest and dividend forms from all bank accounts and brokerage accounts and K-1’s from partnerships</li>
  <li>1099-R for distributions and rollovers from all retirement accounts</li>
  <li>Complete brokerage account information for people who trade equities, including access to accounts or printouts of trades</li>
  <li>1098 mortgage interest and tax forms for homeowners</li>
  <li>Records regarding your charitable contributions</li>
  <li>Social security numbers and dates of birth for members of the family, including newborns</li>
  <li>If you plan to file a joint tax return, please don’t forget to bring a photo of your spouse’s driver’s license, front and back. A picture on your phone is fine.</li>
  <li>For new clients, please have access to a copy of last year’s tax return</li>
  <li>If childcare is being provided, childcare provider’s ID number</li>
  <li>Form 1099-G, unemployment, if applicable</li>
  <li>All expense records</li>
  <li>All 1095 forms for Affordable Care Act reporting</li>
  <li>Forms 1098-T and 1098-E for tuition and interest paid for qualified education</li>
  <li>All other 1099 forms</li>
  <li>All information regarding bank accounts held outside the US for FBAR reporting</li>
  <li>From all purchases and sales of real estate, bring HUD documents</li>
  <li>For processing returns, please have your bank routing and account number</li>
  </ul>
</div>
`

export const FILING_AN_EXTENSION_CONTENT = `
<div>
  <p>If you would like to have an extension filed and we’ve never filed a return on your behalf, please submit the following information:</p>
  <ul>
    <li>W2</li>
    <li>1099</li>
    <li>Name</li>
    <li>Address</li>
    <li>Date of birth</li>
    <li>Email address</li>
    <li>Phone (mobile)</li>
    <li>Social security number</li>
  </ul>
  <p>If you anticipate a balance due, please provide bank account and routing information. If you’re unsure whether a balance will be due and do not provide bank account information, there may be a negligible penalty.</p>
  <p>If you request an extension it is up to you to make sure that we receive your information. Please follow up with a phone call or email and confirm receipt of your information.</p>
  <p>Please call the office to setup your actual tax appointment.</p>
  <p><strong>Email:</strong> lesliefields123@gmail.com<br>
  <strong>Phone:</strong> (212) 867-4273</p>
</div>
`
