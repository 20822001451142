import React from 'react'
import List from 'antd/lib/List'

const LIST_ITEMS = ["W-2 forms","1099-MISC forms for independent contractors","1099 interest and dividend forms from all bank accounts and brokerage accounts and K-1’s from partnerships","1099-R for distributions and rollovers from all retirement accounts","Complete brokerage account information for people who trade equities, including access to accounts or printouts of trades","1098 mortgage interest and tax forms for homeowners","Records regarding your charitable contributions","Social security numbers and dates of birth for members of the family, including newborns","If you plan to file a joint tax return, please don’t forget to bring a photo of your spouse’s driver’s license, front and back. A picture on your phone is fine.","For new clients, please have access to a copy of last year’s tax return","If childcare is being provided, childcare provider’s ID number","Form 1099-G, unemployment, if applicable","All expense records","All 1095 forms for Affordable Care Act reporting","Forms 1098-T and 1098-E for tuition and interest paid for qualified education","All other 1099 forms","All information regarding bank accounts held outside the US for FBAR reporting","From all purchases and sales of real estate, bring HUD documents","For processing returns, please have your bank routing and account number"]

class WhatToBring extends React.Component {
  render () {
    return (
      <List
        size='small'
        bordered
        dataSource={LIST_ITEMS}
        renderItem={item => (<List.Item>{item}</List.Item>)}
      />
    )
  }
}

export default WhatToBring
