
import React from 'react'
import AntdLayout from 'antd/lib/layout'
import Menu from 'antd/lib/menu'
import { Link, withRouter } from 'react-router-dom'
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps'

import { ROUTES } from '../../fixtures'
import './styles.scss'

const OFFICE_LOCATION = `
353 Lexington Ave.<br />
Suite 602<br />
New York, NY 10016
`

const USEFUL_LINKS = `
  <a href="https://www.irs.gov/Forms-&amp;-Pubs" target="_blank">IRS Forms and Publications</a><br>
  <a href="http://www.tax.ny.gov/forms" target="_blank">NYS Forms and Instructions</a><br>
  <a href="https://www.eftps.gov/eftps/" target="_blank">For electronic payment of federal taxes</a><br>
  <a href="http://www.state.nj.us/treasury/taxation/" target="_blank">NJ Forms and Instructions</a> <br>
  <a href="https://www.tax.ny.gov/pit/file/1099g.htm " target="_blank">Link to NYS tax refund</a><br>
  <a href="https://applications.labor.ny.gov/IndividualReg/?TYPE=33554433&amp;REALMOID=06-b0d30d94-a224-104a-b476-839b26b50cb3&amp;GUID=&amp;SMAUTHREASON=0&amp;METHOD=GET&amp;SMAGENTNAME=$SM$ThT5WVUR1XkGiy4UhP34jv09ppEYsY5V%2b%2bhuQYmYxeVseon5yi64QNm3b%2fN7fNeC&amp;TARGET=$SM$HTTPS%3a%2f%2fapplications%2elabor%2eny%2egov%2fIndividual%2f" target="_blank">NYS Unemployment Benefits</a>
`
const CURRENT_YEAR = new Date().getFullYear()

const location = {
  lat: 40.75019643250923,
  lng: -73.97694439048126
}

const Map = withScriptjs(withGoogleMap(
  props => (
    <GoogleMap
      defaultZoom={15}
      defaultCenter={location}
    >
      <Marker position={location} />
    </GoogleMap>
  )
))

class Layout extends React.Component {
  render () {
    const { history: { location } } = this.props
    return (
      <AntdLayout>
        <AntdLayout.Header className='header'>
          <div className='brand'>
            <Link to={'/'}>Leslie S. Fields, CPA</Link>
          </div>
          <Menu
            theme='dark'
            mode='horizontal'
            defaultSelectedKeys={['/']}
            selectedKeys={[location.pathname]}
            style={{ lineHeight: '64px' }}
          >
            <Menu.Item key={'/'}><Link to='/'>Home</Link></Menu.Item>
            <Menu.Item key={'/what-to-bring'}><Link to='/what-to-bring'>What to Bring to Your Tax Appointment</Link></Menu.Item>
            <Menu.Item key={'/filing-an-extension'}><Link to='/filing-an-extension'>Filing an Extension</Link></Menu.Item>
          </Menu>
        </AntdLayout.Header>
        <AntdLayout.Content style={{ padding: '0 50px' }}>
          <AntdLayout style={{ padding: '24px 0', background: '#fff' }}>
            <AntdLayout.Content style={{ padding: '0 24px', minHeight: 280 }}>
              <main>
                {this.props.children}
              </main>
            </AntdLayout.Content>
            <AntdLayout.Sider width={320} style={{ background: '#fff', padding: '20px 20px 20px 0px', display: 'flex', flexDirection: 'column' }}>
              <div className={'gutter'}>
                <h4>Office Location</h4>
                <div dangerouslySetInnerHTML={{ __html: OFFICE_LOCATION }} />
              </div>
              <div className={'gutter'}>
                <Map
                  googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyAwrv7wWjkrsVzvtriwuf3e3vo3FLQW3pg'
                  loadingElement={<div style={{ height: `300px` }} />}
                  containerElement={<div style={{ height: `300px` }} />}
                  mapElement={<div style={{ height: `300px` }} />}
                />
              </div>
              <div className={'gutter'}>
                <h4>Useful Links</h4>
                <div dangerouslySetInnerHTML={{ __html: USEFUL_LINKS }} />
              </div>
            </AntdLayout.Sider>
          </AntdLayout>
        </AntdLayout.Content>
        <AntdLayout.Footer style={{ textAlign: 'center' }}>
          © {CURRENT_YEAR} Leslie S. Fields, CPA
        </AntdLayout.Footer>
      </AntdLayout>
    )
  }
}

export default withRouter(Layout)
