import React from 'react'
import ReactDOM from 'react-dom'
import Layout from './components/Layout'
import { HOME_CONTENT, WHAT_TO_BRING_CONTENT, FILING_AN_EXTENSION_CONTENT } from './fixtures'
import { BrowserRouter, Route } from 'react-router-dom'
import WhatToBring from './containers/WhatToBring'

import 'antd/dist/antd.css'

const UP_STAGE = process.env.UP_STAGE || undefined

const Home = props => <div dangerouslySetInnerHTML={{__html: HOME_CONTENT }} />
const FilingAnExtension = props => <div dangerouslySetInnerHTML={{__html: FILING_AN_EXTENSION_CONTENT }} />

class App extends React.Component {
  render () {
    return (
      <BrowserRouter basename={UP_STAGE}>
        <Layout>
          <Route path='/' component={Home} exact />
          <Route path='/what-to-bring' component={WhatToBring} exact />
          <Route path='/filing-an-extension' component={FilingAnExtension} exact />
        </Layout>
      </BrowserRouter>
    )
  }
}

ReactDOM.render(
  <App />, 
  document.getElementById('root')
)
